<template>
  <div>
    <h1 class="mb-30">Журнал изменений</h1>

    <!--  TODO FILTER  -->
    <VForm class="box filter mb-30" @request="byFiltersChangeLog" ref="formDataRef">
      <div class="grid-filter mb-20">
        <!-- by date -->
        <VInputDate
          label="По дате изменения"
          ref="dateRef"
        />

        <!-- by responsible -->
        <VSelect
          label="По ответственному:"
          name="user_id"
          :options="users"
        />

        <!-- stage -->
        <VSelect
          label="По событию:"
          name="event"
          :options="events"
        />
      </div>
      <div class="buttons">
        <VButton
          text="Показать"
          type="submit"
          color="primary"
          :loading="loadingBtn"
        />

        <VButton
          text="Сбросить"
          @click.native="resetForm"
        />
      </div>
    </VForm>

    <AppTable
      :columns="columns"
      :items="items"
      :loading="loadingTable"
      rowActive
      :isChangelog="true"
      @rowClick="rowClick"
      @sorted="sortedHandler"
    />

    <AppPagination
      v-if="pagination.pageCount > 1"
      :current-page="pagination.page"
      :page-count="pagination.pageCount"
      @setPage="setPage"
    />

    <BaseModal
      v-if="isModal"
      title="Изменения"
      class="modal-changelog"
      @close="closeModal"
    >
      <AppTable
        class="modal__table modal__box custom-scroll"
        :columns="modalTh"
        :items="modalItems"
      />
    </BaseModal>
  </div>
</template>

<script>
import VInputDate from '@/components/Form/VInputDate'
import AppPagination from '@/components/ui/AppPagination'
import AppTable from '@/components/ui/AppTable'
import BaseModal from '@/components/ui/Modal/BaseModal'
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'
import VSelect from '@/components/Form/VSelect'

import { mixinModal } from '@/mixins/modal/mixinModal'

import { ChangelogApi } from '@/api/changelog/ChangelogApi'
import { mapState } from 'vuex'
import { changelogModel } from '@/entities/changelog'
import { orderModel } from '@/entities/order'
import { showAlertError } from '@/utils/store/alert'
import { transformDateForSend } from '@/utils/form/date'
import { getPageCount } from '@/utils/store/pagination'

export default {
  name: 'ChangeLog',
  components: {
    VInputDate,
    AppPagination,
    AppTable,
    BaseModal,
    VForm,
    VButton,
    VSelect
  },
  mixins: [mixinModal],
  data () {
    return {
      loadingBtn: false,
      loadingTable: false,

      items: [],
      columns: [
        {
          title: 'Дата изменений',
          sortable: true,
          sortDesc: true,
          name: 'created_at'
        },
        {
          title: 'Кто изменил',
          sortable: true,
          sortDesc: true,
          name: 'changed',
          sort: 'users.surname'
        },
        {
          title: 'Событие',
          sortable: true,
          sortDesc: true,
          name: 'event'
        },
        {
          title: 'Место изменений',
          name: 'placeChanged'
        }
      ],

      modalItems: [],

      // query
      sort: '-id',
      filter: {},
      pagination: {
        perPage: 20,
        page: 1,
        pageCount: 1
      }
    }
  },

  mounted () {
    this.getAllChangeLog()
  },

  computed: {
    ...mapState('users', {
      users: 'all'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),
    tableHead: () => changelogModel.TABLE_HEAD,
    modalTh: () => changelogModel.MODAL_TH,
    events: () => changelogModel.EVENTS
  },

  methods: {
    async getAllChangeLog (query = {}) {
      query = {
        ...query,
        ...this.filter,
        sort: this.sort,
        'per-page': this.pagination.perPage,
        page: this.pagination.page,
        subject_type: orderModel.CHANGELOG_SUBJECT_TYPE
      }

      this.loadingTable = true
      this.items = []
      try {
        const res = await ChangelogApi.getAll(query)
        this.pagination.pageCount = getPageCount(res)
        this.items = res.data.map(changelogModel.mapData)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.loadingTable = false
        this.loadingBtn = false
      }
    },

    rowClick (item) {
      this.modalItems = changelogModel.mapItemToDetails(item)
      this.openModal()
    },

    async sortedHandler (data) {
      data.sortDesc = !data.sortDesc
      this.sort = (data.sortDesc ? '-' : '') + (data.sort || data.name || 'id')

      await this.getAllChangeLog()
    },

    byFiltersChangeLog (data) {
      this.filter = {
        ...transformDateForSend(this.$refs.dateRef.$refs.dateRef?.defaultValue),
        ...data,
        event: data.event?.value,
        user_id: data.user_id?.id
      }

      this.pagination.page = 1
      this.loadingBtn = true

      this.getAllChangeLog()
    },

    resetForm () {
      this.$refs.formDataRef.clear()
      this.$refs.dateRef.$refs.dateRef._flatpickr.clear()
      this.filter = {}
      this.getAllChangeLog()
    },

    // пагинация
    setPage (pageNumber) {
      this.pagination.page = pageNumber
      this.getAllChangeLog()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__table {
  &::v-deep th {
    &:last-child {
      color: $color__green;
    }

    &:nth-last-child(2) {
      color: $color__red;
    }
  }
}
</style>
